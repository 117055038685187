@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800');

/* inherit the OSA styles */
@import url('https://oregonstudentaid.gov/public/dist/css/styles.css');

/* until we remove all other font-family references (MaterialUI, etc) */
* {
  font-family: Open Sans,sans-serif !important;
}

/* temporary CSS to be integrated into OSA Public CSS stack */

/* debugging classes */
.outlined {
  border: solid 3px red;
}

.pink {
  background-color: pink;
}

.blue {
  background-color: aliceblue;
}

.required {
  color: red;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 1ch;
}

.redText{
  color: red;
}

.pageshell__primary * {
  letter-spacing: 0 !important;
}

/* find them hiding <Grid>s */
.css-15fev7r-MuiGrid-root {
  background-color: pink;
}

.checkbox-wrapper {
  align-items: start;
  display: flex;
}

.checkbox-wrapper__label {
  font-size: .875rem;
  grid-column: 2/span 11;
}

.checkbox-wrapper .MuiCheckbox-root {
  padding: 0 !important;
}

.award-cta {
  text-align: center;
}

.awards-page .grant .award-cta {
  display: none;
}

.awards-page .grant--scholarship .award-cta {
  display: none;
}

.awards-page .grant--scholarship .award-cta.award-cta--accept-decline {
  display: block;
}

.award-cta__status {
  align-items: center;
  display: flex;
  font-size: .875rem;
  gap: 1ch;
  justify-content: center;
}

a.maybebutton{
    background-color: #1a476e;
    border: 2px solid transparent;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-family: Open Sans,sans-serif;
    font-size: .75rem;
    font-weight: 700;
    min-height: 2.5rem;
    min-width: 5rem;
    outline: none;
    padding: 0.5rem 2ch;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    white-space: nowrap;
}
.button {
    line-height: normal;
    text-align: center;
}

.grant .button {
  font-size: .75rem;
  min-height: 1.5rem;
  min-width: 6rem;
  padding: .25rem .5rem;
}

.grant__detail {
  background-color: #fefefe;
}

.awards-page table {
  min-width: 50%;
  margin: 1.5rem auto;
}

.awards-page .grant__content {
  min-height: 0;
  text-align: left;
}

.awards-page .MuiAccordionSummary-root .heading {
  color: #ffffff !important;
}

/* term-table RadioGroup hack */
.row-1 .MuiFormControlLabel-root:nth-child(2),
.row-1 .MuiFormControlLabel-root:nth-child(3) {
  display: none !important;
}

.row-2 .MuiFormControlLabel-root:nth-child(1),
.row-2 .MuiFormControlLabel-root:nth-child(3) {
  display: none !important;
}

.row-3 .MuiFormControlLabel-root:nth-child(1),
.row-3 .MuiFormControlLabel-root:nth-child(2) {
  display: none !important;
}

#tipslist-trigger {
  padding-left: 1.5rem;
  position: relative;
}

#tipslist-trigger svg {
  left: 0;
  position: absolute;
  transition: .2s ease-in;
  transform-origin: center center;
}

#tipslist-trigger.active svg {
  transform: rotate(90deg);
}

.message__header {
  padding: 0;
}

.message__header-from {
  font-size: .875rem;
  margin-bottom: .5rem;
}

.message__header-title {
  line-height: 1.2;
}

.message__body-content {
  padding: .75rem .75rem .75rem 1.25rem !important;
}

.css-173ik18-MuiButtonBase-root-MuiIconButton-root-MuiRadio-root {
  padding: 0 !important;
}

.css-11xzkwv-MuiFormLabel-root-MuiInputLabel-root {
  color: #333333 !important;
}

b {
  font-weight: bold;
}

.top-margin{
  margin-top: 2rem;
}
